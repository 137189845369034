import React from "react"
import Jumbotron from "src/components/jumbotron"
import Layout from "src/components/layout"
import { Grid } from "@material-ui/core"
import EmailInput from "src/components/marketing/email-input"
import GetStartedSection from "src/components/marketing/get-started-section"
import TeamSection from "src/components/marketing/team-section"
import MindanceAppSection from "src/components/marketing/mindance-app-section"
import SignatureSection from "src/components/marketing/signature-section"
import DemoVideoSection from "src/components/marketing/demo-video-section"
import "src/styles/pages/marketing-page.scss"
import TitleStrip from "src/components/marketing/title-strip"
import SEO from "src/components/seo"

const REGISTRATION_CODE = process.env.GATSBY_MIELE_BKK_REGISTRATION_CODE
const ALLOWED_EMAIL_DOMAINS = ["bkk-miele.de", "miele.de", "miele.com"]

export default function MieleBKKLandingPage() {
  const validateEmail = email => {
    if (ALLOWED_EMAIL_DOMAINS.some(domain => email.endsWith(domain))) {
      return null
    }
    return "Dies ist keine geschäftliche E-Mail-Adresse der Miele BKK"
  }

  return (
    <Layout className="marketing-page-root">
      <TitleStrip
        logos={[
          "/assets/img/marketing/miele_bkk_logo.png",
          "/assets/img/marketing/mindance_anmeldung.png",
        ]}
      />
      <Jumbotron
        className="marketing-jumbotron"
        section={{
          image:
            "https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png",
          title:
            "Gezielt für weniger Stress, besseren Schlaf und ein gesteigertes Wohlbefinden sorgen - Mit der Mindance App!",
          description:
            "Mitarbeiter*innen der Miele & Cie KG erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordern Sie Ihren Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen.",
        }}
      >
        <EmailInput
          short
          placeholder="E-Mail geschäftlich"
          registrationCode={REGISTRATION_CODE}
          validate={validateEmail}
        />
      </Jumbotron>
      <SEO
        title="Miele BKK"
        description="Mitarbeiter*innen der Miele & Cie KG erhalten jetzt kostenlos Zugriff auf das Angebot von Mindance. Fordern Sie Ihren Aktivierungscode für die Mindance-App an, um alle Vorteile zu nutzen."
        image="https://mindance-forestry.s3-eu-west-1.amazonaws.com/upload/200306_Mindance_Illus_06@2x.png"
      />
      <Grid container component="section" className="bounded-w">
        <Grid item xs={12} md={6}>
          <h2>Wohlbefinden steigern und Stress reduzieren</h2>
          <p>
            Die vielfältigen Anforderungen des täglichen Lebens können neben
            vielen schönen Momenten auch für Erschöpfung, Frustration und Stress
            sorgen. Hinzu kommen in der Freizeit noch private Herausforderungen.
            Damit daraus keine Überlastung wird, können wir uns durch wertvolles
            psychologisches Hintergrundwissen sowie passende Übungen und Tipps
            in der Mindance App gegen Beeinträchtigungen unseres Wohlbefindens
            schützen, um mental ausgeglichen zu sein. Denn schon vorbeugend ist
            es wichtig, für regelmäßige Entspannung und Ausgleich zu sorgen.
            Probieren Sie es selbst aus.
          </p>
          <br />
          <h2>
            Mindance kostenlos für die Mitarbeiter*innen der Miele & Cie KG
          </h2>
          <p>
            Mindance ist ein Leipziger Unternehmen, das sich der Mission
            verschrieben hat, zu einer Welt beizutragen, in der mentale
            Gesundheit denselben Stellenwert hat wie körperliche Gesundheit und
            in der arbeitsbedingte psychische Erkankungen der Vergangenheit
            angehören.
          </p>
          <br />
          <p className="green-text text-l">
            <b>
              Das ist die Chance für Sie, Ihre psychische Gesundheit nachhaltig
              zu fördern!
            </b>
          </p>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          className="screenshot-container cover-section-edge"
        >
          <img
            className="screenshot"
            alt="feed"
            src="/assets/img/marketing/mindfrinds_achtsamkeit_reflection.jpg"
          />
        </Grid>
      </Grid>
      <MindanceAppSection
        titles={[
          "Checken Sie Ihre mentale Gesundheit und erhalten Sie Ihren persönlichen Übungsplan",
          "Entdecken Sie die große Auswahl an Übungen und Kursen",
          "Behalten Sie Ihre Fortschritte im Blick",
        ]}
        descriptions={[
          "Starten Sie direkt mit unserem Fragebogen, der Ihre psychische Gesundheit detailliert erfasst. Die Ergebnisse zeigen auf, in welchem Bereichen Ihre mentale Gesundheit einer Förderung bedarf. Auf Ihrem persönlichen Ergebnis basierend erhalten Sie einen Plan mit verschiedenen Übungen. Zum Schluss bestimmen Sie selbst, wie viel Zeit Sie pro Woche investieren möchten. Ob einmal wöchentlich 5 Minuten oder täglich 20 Minuten - Sie haben die Wahl.",
          "In der App finden Sie ein 8-wöchiges Grundlagentraining, das Sie dabei unterstützen kann, Ihre eigenen Gedanken und Gefühle besser zu verstehen. Außerdem können Sie nach Belieben aus Übungen zu verschiedenen Themenbereichen, wie “Stress reduzieren”, “Beziehungen stärken”, “Besser schlafen” oder “Achtsamkeit kultivieren” wählen und zwischen 5 und 15 Minuten die Übungslänge einstellen, die für Sie in dem Moment ideal ist.",
          "In Ihrem Profil finden Sie die Timeline Ihrer absolvierten Übungen, Statistiken zu Ihrem Training mit Mindance und die Ergebnisse des Fragebogens. Unser Tipp: Regelmäßiges Üben macht den/die  Meister*in, damit ist Regelmäßigkeit erst einmal wichtiger als langes Üben.",
        ]}
      >
        <DemoVideoSection
          title="Sie sind gespannt, was Sie in der Mindance App erwartet? Dann können sie in diesem Video direkt die erste Übung ausprobieren."
          description="Setzen Sie sich dazu an einen Ort, an dem Sie 5 Minuten ungestört sind und folgen Sie den Anweisungen des Sprechers, der Sie durch den Body Scan leiten wird."
        />
      </MindanceAppSection>
      <TeamSection appointmentScreen={false}>
        <h1>Psychologische Beratung über die Chatfunktion</h1>
        <p>
          <b>
            Sie haben Fragen zum Etablieren einer Routine oder den Themen
            Achtsamkeit, Schlaf, Stress, Leistung und Mitgefühl? Oder Sie
            möchten konkrete Ziele des Trainings gemeinsam mit uns ausloten und
            das Mindance Programm genauer kennen lernen?
          </b>
          <br />
          Dann können Sie direkt in der App unseren psychologischen
          Berater*innen über unsere Chatfunktion schreiben.
        </p>
      </TeamSection>
      <GetStartedSection
        steps={[
          "Laden Sie sich die Mindance App im App Store/PlayStore unter dem Stichwort “Mindance” herunter.",
          "Geben Sie hier Ihre E-Mail Adresse ein, um einen Aktivierungscode zu erhalten.",
          "Erstellen Sie sich unter Verwendung des Aktivierungscodes Ihr persönliches, anonymes Profil. ",
        ]}
        emailComponent={
          <EmailInput
            placeholder="E-Mail Adresse"
            registrationCode={REGISTRATION_CODE}
            validate={validateEmail}
          />
        }
      />
      <SignatureSection />
    </Layout>
  )
}
