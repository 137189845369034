import React from "react"
import PropTypes from "prop-types"
import { Box, Grid } from "@material-ui/core"
import useIsMobile from "src/hooks/useIsMobile"

const DemoVideoSection = ({ title, description }) => {
  const isMobile = useIsMobile()
  return (
    <Box py={4}>
      <Grid container xs={12}>
        <Grid xs={12} md={4}>
          <Box pr={isMobile ? 0 : 4}>
            <h2 style={{ marginTop: 0 }}>{title}</h2>
            <br />
            <p>{description}</p>
          </Box>
        </Grid>
        <Grid xs={12} md={8}>
          <Box position="relative" width height={0} paddingBottom="56.25%">
            <iframe
              src="https://www.youtube.com/embed/Mh1BSEya1dc?controls=0"
              frameBorder="0"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

DemoVideoSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default DemoVideoSection
